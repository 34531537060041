@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;500;600;700&family=Inter:wght@400;600;700&family=Lato:wght@300;400;700&family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;600&display=swap');

body {
  font-family: 'Bai Jamjuree', sans-serif;
}

@keyframes scrollDown {
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(0.6rem);
    opacity: 0;
  }
}

@keyframes scrollUp {
  0% {
    transform: translateY(0.6rem);
    opacity: 0;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-0.6rem);
    opacity: 0;
  }
}
